@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus-visible {
  outline: none;
}

// I have tested but can not use tailwind to render because class is in the ReactTooltip plugin
// future have more time can research have any better resolution
.__react_component_tooltip.place-top {
  &:after {
    left: 90% !important;
  }
}
